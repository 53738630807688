import { render, staticRenderFns } from "./addClasses.vue?vue&type=template&id=3a2300a7&scoped=true"
import script from "./addClasses.vue?vue&type=script&lang=js"
export * from "./addClasses.vue?vue&type=script&lang=js"
import style0 from "./addClasses.vue?vue&type=style&index=0&id=3a2300a7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a2300a7",
  null
  
)

export default component.exports